import { useState } from 'react';

export const SwitcherButton = () => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      <label className="relative inline-flex cursor-pointer select-none items-center">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
          className="sr-only"
        />
        <span
          className={`slider mx-4 flex h-4 w-7 items-center rounded-full p-0 duration-200 ${
            isChecked ? 'bg-[#212b36]' : 'bg-[#CCCCCE]'
          }`}>
          <span
            className={`dot h-3 w-3 rounded-full bg-white duration-200 ${
              isChecked ? 'translate-x-[14px]' : ''
            }`}></span>
        </span>
      </label>
    </>
  );
};
