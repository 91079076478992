import { NOTIFICATION_FAKE_DATA, NotificationsItemProps } from './notification.data';
import { NotificationItem } from './notificationItem';

interface NotificationsListProps {}

export const NotificationsList: React.FC<NotificationsListProps> = () => {
  return (
    <div className="w-full px-4 h-full space-y-2">
      <div className="text-11 font-normal text-skin-six mb-0.5">Today</div>
      {new Array(20)
        .fill(NOTIFICATION_FAKE_DATA[0])
        .map((item: NotificationsItemProps, index: number) => {
          return <NotificationItem key={index} data={item} />;
        })}
    </div>
  );
};
