import { ReactElement } from 'react';

export default interface User {
  id: number;
  name: string;
  email: string;
}

export default interface Note {
  id: number;
  title: string;
  description: string;
}

export type MainLayoutProps = {
  children: React.ReactNode;
  className?: string;
};

export interface RouteItem {
  path: string;
  element: ReactElement;
}
export enum Theme {
  Default = 'default',
  NewTheme = 'new_theme'
}
export type ThemeType = 'default' | 'new_theme';

export interface PropsElement {
  children: React.ReactNode;
}
export interface ReturnFunctionProps {
  [key: string]: () => void;
}
export interface AppStoreZustand {
  token: string | null;
  refreshToken: string | null;
  tempToken: string | null;

  setTempToken: (t: string | null) => void;
  setToken: (t: string | null) => void;
  setRefreshToken: (t: string | null) => void;

  getTempToken: () => string | null | undefined;
  getToken: () => string | null | undefined;
  getRefreshToken: () => string | null | undefined;

  removeTempToken: () => void;
  removeToken: () => void;
  removeRefreshToken: () => void;

  removeAuth: () => void;
}

export type ServerErrorProps = {
  error: {
    message: string;
    code: number;
    status: number;
    params?: object;
  };
};

export interface RequestOptionsProps extends RequestInit {
  headers: {
    'Content-Type': string;
    'Access-Control-Allow-Origin': string;
  };
}
// SVG Icons
export interface SvgIconProps {
  name?: string;
  className?: string;
  size?: number;
  color?: string;
  height?: number;
  width?: number;
  onClick?: () => void;
}
// Bottom Props
export interface BottomProps {
  name?: string;
  className?: string;
  size?: number;
  icon?: ReactElement;
  label?: string;
  width?: number;
  onClick?: () => void;
}