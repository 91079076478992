import { useEffect } from 'react';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { useNotesStore } from 'src/hooks';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import 'src/styles/note.css';

// function OnChangePlugin({ onChange }: { onChange: any }): any {
//   const [editor] = useLexicalComposerContext();
//   useEffect(() => {
//     return editor.registerUpdateListener(({ editorState }) => {
//       onChange(editorState);
//     });
//   }, [editor, onChange]);
// }

function Placeholder() {
  return <div className="editor-placeholder"></div>;
}

function MyCustomAutoFocusPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    // Focus the editor when the effect fires!
    editor.focus();
  }, [editor]);

  return null;
}


export function Notebox() {
  const { noteItem, saveNote }: any = useNotesStore();
  const handleOnChangeTitle = (e) => {
    saveNote({ title: e.target.value });
  };

  // function onChange(editorState) {
  //   console.log("=>(notebox.tsx:44) editorState", editorState);
  //   setEditorState(editorState);
  // }

  return (
    <div className="px-8 py-5 bg-skin-four text-sm overflow-hidden flex-wrap whitespace-pre-wrap">
      <input
        className="font-normal text-base  w-full italic opacity-60 bg-transparent 
        overflow-hidden appearance-none focus:outline-none focus:ring-0 peer border-transparent"
        placeholder="Add Title"
        onChange={handleOnChangeTitle}
        defaultValue={noteItem?.title}
      ></input>

      <RichTextPlugin
        contentEditable={
          <ContentEditable
            id="note-paper"
            rows={15}
            cols={50}
            className=" editor-input
            resize-none w-full h-full pe-1 text-15 font-medium leading-[34px] text-justify mt-3
            overflow-auto appearance-none focus:outline-none focus:ring-0 peer border-transparent
            "
          />
        }
        placeholder={<Placeholder />}
        ErrorBoundary={LexicalErrorBoundary}
      />
      {/* active CTRL-Z , CTRL-SHIFT-Z */}
      <HistoryPlugin />

      <MyCustomAutoFocusPlugin />
      {/*<OnChangePlugin onChange={onChange} />*/}
    </div>
  );
}
