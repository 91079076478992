import React from 'react';
import { createRoot } from 'react-dom/client';
import ThemeProvider from './providers/themeContext';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AppRoutes } from './routes/Routes';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

//import all css files
import './styles/index.css';
import AppLayout from './layouts/app';

const queryClient = new QueryClient();

const domNode = document.getElementById('root')!;
const root = createRoot(domNode);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <AppLayout>
          <AppRoutes />
        </AppLayout>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={true} buttonPosition={'bottom-right'} />
    </QueryClientProvider>
  </React.StrictMode>
);

// root.unmount();
// reportWebVitals(console.log);
