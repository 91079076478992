const Icon = {
  svgWarnIcon: `<path d="M12 4L4.45312 16.9844H19.5469L12 4ZM12 0.015625L23.0156 19H0.984375L12 0.015625ZM12.9844 13.9844V16H11.0156V13.9844H12.9844ZM12.9844 7.98438V13H11.0156V7.98438H12.9844Z" fill="#FB8C00"/>`,
  svgInfoIcon: `<path
                d="M4.32812 15.6719C5.92188 17.2344 7.8125 18.0156 10 18.0156C12.1875 18.0156 14.0625 17.2344 15.625 15.6719C17.2188 14.0781 18.0156 12.1875 18.0156 10C18.0156 7.8125 17.2188 5.9375 15.625 4.375C14.0625 2.78125 12.1875 1.98438 10 1.98438C7.8125 1.98438 5.92188 2.78125 4.32812 4.375C2.76562 5.9375 1.98438 7.8125 1.98438 10C1.98438 12.1875 2.76562 14.0781 4.32812 15.6719ZM2.92188 2.96875C4.89062 1 7.25 0.015625 10 0.015625C12.75 0.015625 15.0938 1 17.0312 2.96875C19 4.90625 19.9844 7.25 19.9844 10C19.9844 12.75 19 15.1094 17.0312 17.0781C15.0938 19.0156 12.75 19.9844 10 19.9844C7.25 19.9844 4.89062 19.0156 2.92188 17.0781C0.984375 15.1094 0.015625 12.75 0.015625 10C0.015625 7.25 0.984375 4.90625 2.92188 2.96875ZM9.01562 4.98438H10.9844V10.9844H9.01562V4.98438ZM9.01562 13H10.9844V15.0156H9.01562V13Z"
                fill="#314D85"
                />`,
  svgErrorIcon: `<path
                d="M4.32812 15.6719C5.92188 17.2344 7.8125 18.0156 10 18.0156C12.1875 18.0156 14.0625 17.2344 15.625 15.6719C17.2188 14.0781 18.0156 12.1875 18.0156 10C18.0156 7.8125 17.2188 5.9375 15.625 4.375C14.0625 2.78125 12.1875 1.98438 10 1.98438C7.8125 1.98438 5.92188 2.78125 4.32812 4.375C2.76562 5.9375 1.98438 7.8125 1.98438 10C1.98438 12.1875 2.76562 14.0781 4.32812 15.6719ZM2.92188 2.96875C4.89062 1 7.25 0.015625 10 0.015625C12.75 0.015625 15.0938 1 17.0312 2.96875C19 4.90625 19.9844 7.25 19.9844 10C19.9844 12.75 19 15.1094 17.0312 17.0781C15.0938 19.0156 12.75 19.9844 10 19.9844C7.25 19.9844 4.89062 19.0156 2.92188 17.0781C0.984375 15.1094 0.015625 12.75 0.015625 10C0.015625 7.25 0.984375 4.90625 2.92188 2.96875ZM9.01562 4.98438H10.9844V10.9844H9.01562V4.98438ZM9.01562 13H10.9844V15.0156H9.01562V13Z"
                fill="#FF5252"
                />`
};

export const toastFn = () => {
  const base = ({ text, textColor, bgColor, svgIcon, timer }) => {
    const toastElement = document.getElementById('toast-element');
    if (toastElement) {
      return;
    }
    try {
      const element = document.createElement('div');
      element.id = 'toast-element';
      element.classList.add(
        'h-12',
        'fixed',
        'bottom-0',
        'left-0',
        'right-0',
        'z-10',
        bgColor,
        'mx-auto',
        'w-full',
        'max-w-default',
        'min-w-default',
        'gap-x-3',
        'rounded',
        'flex',
        'justify-start',
        'items-center',
        'px-4',
        textColor,
        'text-sm'
      );
      const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      svg.setAttribute('width', '24');
      svg.setAttribute('height', '24');
      svg.innerHTML = svgIcon;
      element.appendChild(svg);
      const message = document.createElement('div');
      message.textContent = text || '';
      element.appendChild(message);

      const removeToast = () => {
        try {
          document.body && document.body.removeChild(element);
          element.removeEventListener('click', removeToast);
        } catch (error) {
          console.log('😏 => error:', error);
        }
      };

      element.addEventListener('click', removeToast);
      setTimeout(removeToast, timer || 10000);

      document.body && document.body.appendChild(element);
    } catch (error) {
      console.log(error);
    }
  };
  const warn = (text) => {
    base({
      text,
      textColor: 'text-yellow-900',
      bgColor: 'bg-orange-50',
      svgIcon: Icon.svgWarnIcon,
      timer: 10000
    });
  };
  const error = (text) => {
    base({
      text,
      textColor: 'text-pink-950',
      bgColor: 'bg-rose-100',
      svgIcon: Icon.svgErrorIcon,
      timer: 10000
    });
  };
  const info = (text) => {
    base({
      text,
      textColor: 'text-blue-900',
      bgColor: 'bg-blue-50',
      svgIcon: Icon.svgInfoIcon,
      timer: 10000
    });
  };
  return {
    warn,
    error,
    info
  };
};
