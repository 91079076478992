import { useMutation } from '@tanstack/react-query';
import { Constants } from 'src/constants/Constants';
import { Services } from 'src/services/services';
import { useNotesStore } from './useNoteStore';
import { toastFn } from '../../components';
import { useNavigate } from 'react-router-dom';

export const useNoteService = () => {
  const { saveNote }:any = useNotesStore();
  const navigate = useNavigate();
  const toast = toastFn();

  const reactQuery = useMutation({
    mutationKey: [Constants.ReactQuery.NOTES],
    mutationFn: Services.Note.post,
    onSuccess: (data: any) => {
      if (data.success) {
        const { uid } = data;
        saveNote({ uid });
        navigate(Constants.RouteName.SHARE_NOTE);
      }
    },
    onError: (err: any) => {
      console.log('😏 => err:', err);
      toast.error('Error submit');
    }
  });

  const post = (params) => {
    const body = JSON.stringify(params.body);
    reactQuery.mutate({ ...params, body });
  };

  return {
    error: {},
    post,
    data: {},
    isLoading: reactQuery?.isPending
  };
};
