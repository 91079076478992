export const Utils = {
  Generator: {
    uuid: function uuidv4(): string {
      return ([1e7].join() + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: string) => {
        try {
          const safeVel = crypto.getRandomValues(new Uint8Array(1));
          if (safeVel && Array.isArray(safeVel)) {
            const r = safeVel[0] & (15 >> (Number(c) / 4));
            return (Number(c) ^ r).toString(16);
          }
        } catch (error) {
        }
        return '';
      });
    },
    generateID: function(length = 6) {
      return Math.random()
        .toString(36)
        .substring(2, length + 2);
    }
  },
  Errors: {
    ConvertErrorToJSON: function(errorArray) {
      return errorArray.reduce((acc, { field_name, field_error }) => {
        acc[field_name] = field_error;
        return acc;
      }, {});
    }
  },
  Convert: {
    addSecondsToDate: (seconds: number) => {
      try {
        const currentDate = new Date();
        const futureDate = new Date(currentDate.getTime() + seconds * 1000);
        return futureDate;
      } catch (err) {
        return null;
      }
    }
  }
};
