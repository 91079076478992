import axios from 'axios';
import { toastFn } from 'src/components';

const toast = toastFn();

const api = axios.create({
  baseURL: process.env['REACT_APP_BASE_URL']
});

api.interceptors.request.use(
  (req) => {
    const token = localStorage.getItem('token');
    if (token) {
      req.headers.Authorization = `Bearer ${token}`;
    }
    return req;
  },
  (err) => {
    console.error('😏 => Error code: ', err.response.data.message);
    return err;
  }
);

api.interceptors.response.use(
  (response) => {
    // success fn
    return response;
  },
  (error) => {
    console.error('😏 => error:', error);
    // error fn
    const { response } = error || {};
    toast.error(response.statusText || 'Something went wrong');
    // if (response.status === 400 || response.status === 401) {
    //   const { data } = response;
    //   if (!data.success) {
    //     toast.info(data.error.msg);
    //     console.error(`😏 => Error code: ${data.error.code}, message: ${data.error.msg}`);
    //   }
    // }
    //todo: refresh token
    //#region
    // const originalRequest = error.config;
    // if (error.response.status === 401 && !originalRequest._retry) {
    //   originalRequest._retry = true;
    //   return api.post('/auth/token', {
    //       refresh_token: refreshToken
    //     })
    //     .then((res) => {
    //       if (res.status === 201) {
    //         api.defaults.headers.common['Authorization'] = 'Bearer ' + 'token';
    //         return api(originalRequest);
    //       }
    //     });
    // }
    //#endregion
    return Promise.reject(error?.response?.data);
  }
);

export default api;
