import { useState, useEffect } from 'react';

/**
 * Generates a countdown timer based on a target end date.
 *
 * @param {Date} targetEndDate - The target end date for the countdown timer.
 * @return {Object} - An object containing the countdown values for days, hours, minutes, and seconds.
 */
const useCountdownDate = (targetEndDate) => {
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetEndDate - now;

      if (distance < 0) {
        clearInterval(interval);
        setCountdown({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0
        });
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setCountdown({
          days,
          hours,
          minutes,
          seconds
        });
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [targetEndDate]);

  return countdown;
};
export default useCountdownDate;
