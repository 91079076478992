export interface NotificationsItemProps {
  id: number;
  firstName: string;
  lastName: string;
  avatar: string;
  date: string;
}

export const NOTIFICATION_FAKE_DATA: NotificationsItemProps[] = [
  {
    id: 1,
    firstName: 'Marilyn',
    lastName: 'Beck',
    date: ' Just Now',
    avatar: 'https://picsum.photos/40'
  },
  {
    id: 2,
    firstName: 'Marilyn',
    lastName: 'Beck',
    date: '2 days ago',
    avatar: 'https://picsum.photos/40'
  },
  {
    id: 3,
    firstName: 'Marilyn',
    lastName: 'Beck',
    date: ' Just Now',
    avatar: 'https://picsum.photos/40'
  },
  {
    id: 4,
    firstName: 'Marilyn',
    lastName: 'Beck',
    date: '2 days ago',
    avatar: 'https://picsum.photos/40'
  }
];
