import { LikeSVG } from '../../assets/svg/svgIcons';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import exampleTheme from '../../pages/testCode/themes/ExampleTheme';
import initialState from 'src/constants/fakeData/initialState.json';

type CommentType = {
  id: number;
  name: string;
  comment: string;
  pId: number | null;
}
type CommentTypeContainer = {
  data: CommentType[];
}
var editorConfig: any = {
  // The editor theme
  theme: exampleTheme,
  // Handling of errors during update
  onError(error) {
    throw error;
  },
  editable: false,
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode
  ]
};

export const Comment = (props: CommentTypeContainer) => {
  const { data }: { data: CommentType[] } = props;

  editorConfig.editorState = JSON.stringify(initialState);
  editorConfig.editable = true;

  return <div className={"pb-8"}>{data?.map((item: CommentType) => {
    const isChild = !!item.pId;
    const className = isChild ? 'ms-8 w-72' : 'w-80 ms-2';
    return <div key={item.id} className={`h-fit relative mt-4 ${className}`}>
      <div className={'flex justify-start items-center gap-x-2 '}>
        <div className="">
          <div className="w-7 h-7 bg-zinc-300 rounded-full">
            <img className="w-7 h-7  rounded-full"
                 src={require('src/assets/images/dev/fake_image.png')}
                 alt={'profile_image'} />
          </div>
        </div>
        <div
          className="opacity-90 text-stone-950 text-xs font-medium">Marilyn Beck
        </div>
        <div
          className=" opacity-60 text-stone-950 text-xs font-normal  leading-3">1m
        </div>
      </div>
      <LexicalComposer initialConfig={editorConfig}>
        <ContentEditable
          readOnly={true}
          contentEditable={true}
          suppressContentEditableWarning={false}
          className="not-editable opacity-90 text-stone-950 text-xs font-normal leading-5 mt-1.5"
          spellCheck={false}
          id="note-text-view"
        />
      </LexicalComposer>

      <div className="w-20">
        <div className={'p-1 flex justify-start items-center gap-x-4'}>
          <div
            className="flex opacity-70 text-stone-950 text-xs font-normal leading-3">
            <LikeSVG size={16} color="#0D0D0D" className="opacity-60" />
            <div className="text-xs font-normal text-zinc-600 ms-0.5">16K</div>
          </div>
          <div
            className="opacity-60 text-stone-950 text-11 font-normal leading-3">Reply
          </div>
        </div>
      </div>

    </div>;
  })}
  </div>;
};