import { useState } from 'react';
import { LogoSVG, TextLogoSVG } from 'src/assets/svg/svgIcons';
import ButtonDef from 'src/components/buttons/buttonDef';
import InputDef from 'src/components/inputs/inputDef';
import { LinkHasBeenExpired } from './linkHasBeenExpired';
import { useForgetPasswordReferrerValidation } from 'src/hooks/formValidation/useForgetPasswordReferrerValidator';

export function ForgetPasswordReferred() {
  const [isSubmit, setIsSubmit] = useState(false);
  const { errors, isError, handleSubmit, register } = useForgetPasswordReferrerValidation();

  const onSubmit = (data) => {
    // if (isLoading) return;
    const { password, newPassword } = data || {};
    if (password?.length && password === newPassword) {
      setIsSubmit(true);
    }
  };

  if (isSubmit) return <LinkHasBeenExpired />;
  return (
    <div className="w-full m-auto bg-skin-five h-screen text-skin-six">
      <div className=" select-none flex flex-col justify-start items-center">
        <div className="pt-10 w-full flex justify-center items-center gap-x-2.5">
          <LogoSVG />
          <TextLogoSVG />
        </div>
        <div className="mt-11 m-auto">
          <span className="text-opacity-70 text-sm text-center font-medium leading-relaxed tracking-wide">
            Set your new password and continue
          </span>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputDef
            {...register('password')}
            placeholder={'Password'}
            type={'password'}
            error={errors['password']?.message || ''}
            className="mt-8"
            autoComplete="new-password"
          />
          <InputDef
            {...register('newPassword')}
            placeholder={'New Password'}
            type={'password'}
            className="mt-3"
            error={errors['newPassword']?.message || ''}
            autoComplete="new-password"
          />
          <ButtonDef
            type={'submit'}
            label={'NEXT'}
            className={'text-skin-t2 bg-skin-one mt-8 m-auto'}
            disabled={isError}
            isLoading={false}
          />
        </form>
      </div>
    </div>
  );
}
