import { ArrowIcon, SearchSVG } from '../../assets/svg/svgIcons';
import Navbar from '../../components/navbar/navbar';
import { useNavigate } from 'react-router-dom';

export const FollowPage = () => {
  const navigate = useNavigate();
  return <div className={'bg-skin-five'}>
    <div className="w-full gap-x-2 px-3 flex items-center justify-start h-16 bg-skin-t6 rounded-bl-3xl rounded-br-3xl">
      <ArrowIcon.Left onClick={() => navigate(-1)} />
      <img
        className="w-8 h-8 mt-0.5 bg-contain object-contain rounded-full"
        src={require('src/assets/images/dev/fake_image.png')}
        alt={`sssss`}
      />
      <div className="grow ms-1">
        <div className="flex justify-start items-center gap-x-1">
            <span className="text-skin-six text-base font-medium">
             Ali Ahmadi
            </span>
        </div>
      </div>
    </div>

    <div className={'p-6 w-full'}>
      <div className="w-full h-7 justify-start items-start gap-4 inline-flex">
        <div
          className="px-2.5 py-1.5 bg-skin-one rounded-2xl justify-center items-center gap-2.5 flex cursor-pointer hover:opacity-80">
          <div className="text-center text-white text-xs font-medium">Followers (12K)</div>
        </div>
        <div
          className="px-2.5 py-1.5 bg-skin-t6 rounded-2xl justify-center items-center gap-2.5 flex cursor-pointer hover:opacity-80">
          <div className="text-center text-neutral-600 text-xs font-normal">Followings (150)</div>
        </div>
      </div>
      <div className="mt-4 w-full h-9 relative">
        <div className="w-full h-9 bg-skin-t6 rounded-2xl flex justify-start items-center">
          <div
            className="opacity-50 text-stone-950 text-xs font-normal p-2">
            <SearchSVG size={16} />
          </div>
          <input className="opacity-50 text-stone-950 text-xs font-normal
          block px-0 py-2.5 pe-8 w-full bg-transparent rounded-r
           text-black/80 appearance-none focus:outline-none
           focus:ring-0 focus:border-transparent
          " placeholder={'Search'} />

        </div>
      </div>

      <div className={'gap-y-4 overflow-auto h-full'}>
        {new Array(8).fill(null).map((_item, index) => {
          return <div key={`follow-${index}`}
                      className="w-full h-14 relative justify-start items-center flex my-4 gap-2 border-b border-b-gray-300">
            <img className="w-10 h-10 rounded-full" src={require('src/assets/images/dev/fake_image.png')} alt={'sss'} />
            <div className={'flex-col justify-start items-center'}>
              <div className="opacity-90 text-stone-950 text-sm font-normal">Marilyn Beck</div>
              <div className="opacity-60 text-stone-950 text-xs font-normal leading-3">Thinker. Gamer. Bacon
                ninja...
              </div>
            </div>
            <div
              className="px-2.5 py-1 bg-skin-one rounded-2xl justify-end items-center gap-2.5 inline-flex absolute end-0 cursor-pointer hover:opacity-80">
              <div className="text-center text-white text-xs font-medium">Follow</div>
            </div>
          </div>;
        })}
      </div>

    </div>
    <Navbar activeIndex={3} />
  </div>;
};