import { FloatButton } from 'src/components/buttons/floatBottom';
import NoteItem from 'src/components/card/noteItem';
import initialState from 'src/constants/fakeData/initialState.json';

export const HomeBody = () => {
  return (
    <div className="h-full overflow-y-hidden overflow-x-auto w-full px-4 m-auto">
      {new Array(15).fill(null).map((_, index) => {
        return (
          <NoteItem
            key={`note-item-key-${index}`}
            readOnly={true}
            data={{
              uid: `uid-${index}`,
              title: `Note ${index}`,
              body: JSON.stringify(initialState),
              likes: "17K",
              comments: "2.6K",
              visits: "2M",
              shares: "1K",
            }}
          />
        );
      })}

      <FloatButton position="bottom-right" />
    </div>
  );
};
