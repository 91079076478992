// import { NoteUtilsIcon } from 'src/assets/svg/svgIcons';
import ToolbarPlugin from '../testCode/plugins/ToolbarPlugin';

export function NoteTools() {
  return (
    <div className="fixed w-full bottom-1 px-1 max-w-default min-w-default bg-black bg-opacity-10">
      <div className="grid grid-cols-8 gap-1">
        <ToolbarPlugin />
      </div>
    </div>
  );
}
