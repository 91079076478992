import useCountdownDate from 'src/hooks/useCountdownDate';

export const TimerCounter = (params) => {
  const { targetTimer, onAction, ...props } = params;
  const { minutes, seconds } = useCountdownDate(targetTimer);
  if (minutes + seconds === 0) {
    onAction('done');
    return <div>Resend</div>;
  }
  return <div {...props}>{`${minutes}:${seconds}`}</div>;
};
