import { useNavigate } from 'react-router-dom';
import { LogoCircleSVG } from 'src/assets/svg/svgIcons';
import { Constants } from 'src/constants/Constants';

export const FloatButton = (props) => {
  console.log("=>(floatBottom.tsx:38) props", props);
  const navigate = useNavigate();
  const routeTo = (pathName: string) => {
    navigate(pathName || '/');
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 max-w-default min-w-default m-auto w-full">
      <div className="group absolute bottom-16 right-3 flex items-end justify-end w-24 h-24 ">
        <div
          onClick={() => routeTo(Constants.RouteName.NEW_NOTE)}
          className="flex items-center justify-center rounded-full z-10 absolute  ">
          <LogoCircleSVG className="group-hover:opacity-80 transition-all duration-[0.3s]" />
        </div>
        {/* <div
          onClick={() => routeTo(Constants.RouteName.NEW_NOTE)}
          className="absolute rounded-full transition-all duration-[0.2s] ease-out scale-y-0 group-hover:scale-y-100 group-hover:-translate-x-20   flex  p-2 hover:p-3 bg-skin-one scale-100 text-white">
          <EditSVG color={'#fff'} />
        </div>
        <div
          onClick={() => routeTo(Constants.RouteName.NEW_NOTE)}
          className="absolute rounded-full transition-all duration-[0.2s] ease-out scale-x-0 group-hover:scale-x-100 group-hover:-translate-y-20  flex  p-2 hover:p-3 bg-skin-one  text-white">
          <NotesSVG color="#fff" />
        </div>
        <div
          onClick={() => routeTo(Constants.RouteName.NOTIFICATION)}
          className="absolute rounded-full transition-all duration-[0.2s] ease-out scale-x-0 group-hover:scale-x-100 group-hover:-translate-y-16 group-hover:-translate-x-16   flex  p-2 hover:p-3 bg-skin-one text-white">
          <SharedSVG color="#fff" />
        </div>*/}
      </div>
    </div>
  );
};
