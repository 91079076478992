import MainLayout from '../../layouts/main';
import { Constants } from '../../constants/Constants';
import { ArrowIcon } from '../../assets/svg/svgIcons';
import { useNavigate } from 'react-router-dom';
import NoteItem from '../../components/card/noteItem';
import { Comment } from '../../components/comment/Comment';
import initialState from 'src/constants/fakeData/initialState.json';

type CommentType = {
  id: number;
  name: string;
  comment: string;
  pId: number | null;
}

const FAKE_DATA: CommentType[] = [
  { id: 1, name: 'ali', comment: 'salam', pId: null },
  { id: 2, name: 'reza', comment: 'khobi?', pId: 1 },
  { id: 3, name: 'hadi', comment: 'lol', pId: 1 },
  { id: 4, name: 'hadi', comment: 'say pipe not say lol ', pId: null }
];

export function SingleViewOfNote() {
  const navigate = useNavigate();

  const routeTo = (pathName: string) => {
    navigate(pathName);
  };
  return (
    <MainLayout className="w-full h-screen m-auto bg-skin-five flex-col justify-between items-center pt-6 text-skin-t1">
      <section className={'p-4'}>
        <div className="flex items-center cursor-pointer" onClick={() => routeTo(Constants.RouteName.HOME)}>
          <ArrowIcon.Left size={18} color="#000" onClick={() => navigate(-1)} />
        </div>
        <div className={'h-full py-4'}>
          <NoteItem
            readOnly={true}
            data={{
              uid: `uid-${1}`,
              title: `Note ${1}`,
              body: JSON.stringify(initialState)
            }}
          />
          <div className="py-0 opacity-90 text-stone-950 text-xs font-semibold">Comments</div>
          <Comment data={FAKE_DATA} />
        </div>
      </section>
    </MainLayout>
  );
}
