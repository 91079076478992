import { Navigate, Outlet } from 'react-router-dom';
import { Constants } from 'src/constants/Constants';
import {
  Home,
  Login,
  Register,
  ForgetPassword,
  Otp,
  Profile,
  NewNote,
  ShareNote,
  Notification,
  ForgetPasswordReferred,
  TestCode
} from 'src/pages';
import { SingleViewOfNote } from '../pages/singleViewOfNote/SingleViewOfNote';
import { FollowPage } from '../pages/FollowPage/follow';

export const publicRoutes = [
  { path: Constants.RouteName.LOGIN, element: <Login /> },
  { path: Constants.RouteName.REGISTER, element: <Register /> },
  { path: Constants.RouteName.FORGET_PASSWORD, element: <ForgetPassword /> },
  { path: Constants.RouteName.FORGET_PASSWORD_REFFERED, element: <ForgetPasswordReferred /> }
];

export const otpRoutes = [{ path: Constants.RouteName.OTP, element: <Otp /> }];

export const privateRoutes = [
  { path: Constants.RouteName.HOME, element: <Home /> },
  { path: Constants.RouteName.PROFILE, element: <Profile /> },
  { path: Constants.RouteName.NEW_NOTE, element: <NewNote /> },
  { path: Constants.RouteName.SHARE_NOTE, element: <ShareNote /> },
  {
    path: Constants.RouteName.NEW_NOTE + '/' + Constants.RouteName.SHARE_NOTE,
    element: <ShareNote />
  },
  { path: Constants.RouteName.NOTIFICATION, element: <Notification /> },
  { path: Constants.RouteName.SINGLE_VIEW_NOTE, element: <SingleViewOfNote /> },
  { path: Constants.RouteName.FOLLOW_UNFOLLOW, element: <FollowPage /> },
  { path: '/test', element: <TestCode /> }
];
export const ProtectedRoute = (props) => {
  const { isAllowed, redirectPath } = props || {};
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};
