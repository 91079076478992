import { Constants } from 'src/constants/Constants';
import axios from 'src/services/api';
import {
  ForgetPasswordParams,
  GetNoteById,
  LoginParams,
  LoginResponse,
  PostNoteService,
  RegisterDataResponse,
  RegisterParams,
  VerifyRegisterDataResponse,
  VerifyRegisterParams
} from 'src/types';

export const Services = {
  Auth: {
    login: async (params: LoginParams) =>
      await axios
        .post(Constants.EndpointServer.AUth.LOGIN, { ...params })
        .then(({ data }: { data: LoginResponse }) => data),

    register: async (params: RegisterParams) =>
      await axios
        .post(Constants.EndpointServer.AUth.REGISTER, { ...params })
        .then(({ data }: { data: RegisterDataResponse }) => data),

    verifyRegister: async (params: VerifyRegisterParams) =>
      await axios
        .post(Constants.EndpointServer.AUth.VERIFY_REGISTER, { ...params })
        .then(({ data }: { data: VerifyRegisterDataResponse }) => data),

    forgetPassword: async (params: ForgetPasswordParams) =>
      await axios
        .post(Constants.EndpointServer.AUth.FORGET_PASSWORD, { ...params })
        .then((res: any) => res.data),

    googleLogin: async () => await axios.get(Constants.EndpointServer.AUth.GOOGLE_LOGIN)
  },
  Note: {
    get: async (params: GetNoteById) =>
      await axios
        .get(Constants.EndpointServer.NOTES + `${params.note_uid}/`)
        .then((res: any) => res.data),
    getList: async (params: any) =>
      await axios.post(Constants.EndpointServer.NOTES, { ...params }).then((res: any) => res.data),
    post: async (params: PostNoteService) =>
      await axios.post(Constants.EndpointServer.NOTES, { ...params }).then((res: any) => res.data),

    put: async () => {
      const response = {};
      return response;
    },
    delete: async () => {
      const response = {};
      return response;
    }
  },
  Feed: {
    get: async (params: any) =>
      await axios.get(Constants.EndpointServer.FEED, { ...params }).then((res: any) => res.data)
  }
};
