import { useNavigate } from 'react-router-dom';
import { useAppStore } from '../useAppStore';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Constants } from 'src/constants/Constants';
import { Services } from 'src/services/services';
import { Utils } from 'src/utils/utils';
import { AppStoreZustand, ServerErrorProps } from 'src/types/global.type';
import { toastFn } from 'src/components';
import { ErrorDictionary } from 'src/constants/dictionary';
import { RegisterParams, RegisterResponse } from 'src/types';

export const useRegisterService = () => {
  const { setTempToken }: AppStoreZustand = useAppStore();
  const toast = toastFn();
  const navigate = useNavigate();
  const [serverErrors, setServerErrors] = useState<RegisterParams>({
    email: '',
    username: '',
    display_name: '',
    password: ''
  });

  const reactQuery = useMutation({
    mutationKey: [Constants.ReactQuery.REGISTER],
    mutationFn: Services.Auth.register,
    onSuccess: ({ data }: { data: RegisterResponse }) => {
      const { temp_token } = data || {};
      setTempToken(temp_token);
      navigate('/otp');
    },
    onError: (err: ServerErrorProps) => {
      const { error } = err || {};
      if (error?.params) {
        const JsonError = Utils.Errors.ConvertErrorToJSON(error.params);
        setServerErrors(JsonError);
      } else {
        const textError = ErrorDictionary.Auth[error.code] || error.message;
        toast.error(textError);
      }
    }
  });
  const post = (params: RegisterParams) => {
    reactQuery.mutate({
      ...params
    });
  };

  return {
    serverErrors,
    post,
    isLoading: reactQuery.isPending
  };
};
