interface NotificationItemProps {
  data: any;
  actionButton?: JSX.Element | null;
}
export function NotificationItem(props: NotificationItemProps) {
  const { data, actionButton } = props;
  return (
    <div className="w-full h-14 relative border-b border-stone-200 hover:opacity-70 cursor-pointer">
      <div className="h-10 flex justify-start items-center">
        <img
          className="w-10 h-10 mt-0.5 bg-contain object-contain rounded-full"
            src={require('src/assets/images/dev/fake_image.png')}
          // src={data.avatar}
          alt={`${data.firstName} ${data.lastName}`}
        />
        <div className="mt-1 grow ms-1">
          <div className="flex justify-start items-center gap-x-1">
            <span className="text-stone-950 text-xs font-medium">
              {data.firstName} {data.lastName}
            </span>
            <span className="text-stone-950 text-xs font-normal">Liked your </span>
            <span className="text-blue-900 text-xs font-medium">Note</span>
          </div>
          <div className="mt-1.5 opacity-60 text-stone-950 text-11 font-normal leading-[10px]">
            {data.date}
          </div>
        </div>
        <div className="h-full items-center flex">{actionButton}</div>
      </div>
      <div className="w-full h-0"></div>
    </div>
  );
}
