import { createContext, useContext, useState } from 'react';
import { PropsElement, Theme, ThemeType } from 'src/types/global.type';
const ThemeContext = createContext({});

/**
 * Component that provides the theme context for its children.
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child elements.
 * @returns {ReactNode} - The themed child elements.
 */
const ThemeProvider = (props: PropsElement) => {
  const { children } = props || {};
  // Initialize theme state with default value
  const [theme, setTheme] = useState<ThemeType>(Theme.Default);

  /**
   * Function to handle theme change.
   * @param {string} themeName - The name of the new theme.
   */
  const handleSetTheme = (themeName) => {
    // document.getElementById("root").className = themeName;
    setTheme(themeName);
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme: handleSetTheme }}>
      <div className={theme}>{children}</div>
    </ThemeContext.Provider>
  );
};
export const useTheme = () => useContext(ThemeContext);
export default ThemeProvider;
