import React, { memo, useState } from 'react';
import { EyeSVG, EyeSlashSVG, WarningSVG } from 'src/assets/svg/svgIcons';
function Input(props, ref) {
  const { className = '', error, type, ...other } = props || {};
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };
  return (
    <>
      <div
        className={`relative flex m-auto border-b border-b-black/40  w-80 h-8 ${className} ${
          error && 'border-b-red-500 '
        }`}>
        <input
          ref={ref}
          className={`block px-0 py-2.5 pe-8 w-full bg-transparent rounded-r 
           text-sm text-black/80 appearance-none focus:outline-none
           focus:ring-0 focus:border-transparent 
           ${error && 'text-red-500 placeholder:text-red-500'}`}
          autoCorrect="off"
          autoComplete="off"
          type={showPassword ? 'text' : type}
          {...other}
        />
        {type === 'password' && (
          <div
            className="absolute right-2 top-1/2 -translate-y-1/2 cursor-pointer "
            onClick={toggleShowPassword}>
            {showPassword
              ? EyeSlashSVG({ color: 'var(--color-six)' })
              : EyeSVG({ color: 'var(--color-six)' })}
          </div>
        )}
      </div>
      {error ? (
        <div className="text-xs w-80 m-auto flex justify-start items-center text-red-600 h-3 mt-2">
          <WarningSVG />
          <div className="mx-2 truncate">{error}</div>
        </div>
      ) : (
        <div className="h-3 mt-2 " />
      )}
    </>
  );
}
const InputDef = React.forwardRef(Input);
export default memo(InputDef);
