import { PropsElement } from 'src/types';

/**
 * AppLayout component.
 * 
 * @param {PropsElement} props - The props for the component.
 * @returns {ReactElement} The rendered component.
 */
function AppLayout(props: PropsElement) {
  const { children } = props;
  return <div className="max-w-default min-w-default bg-skin-five mx-auto">{children}</div>;
}

export default AppLayout;
