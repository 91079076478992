import Navbar from 'src/components/navbar/navbar';
import { MainLayoutProps } from 'src/types/global.type';

const MainLayout = ({ children, className }: MainLayoutProps) => {
  return (
    <div className={className}>
      {children}
      <Navbar />
    </div>
  );
};
export default MainLayout;
