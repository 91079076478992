export const Constants = {
  BaseUrl: {
    API: 'http://localhost',
    MASTER_PORT: '3500',
    SOCKET_PORT: '4000',
    VERSION: `/api/v1`
  },
  RouteName: {
    HOME: '/',
    LOGIN: '/login',
    REGISTER: '/register',
    OTP: '/otp',
    FORGET_PASSWORD: '/forget-password',
    FORGET_PASSWORD_REFFERED: '/forget-password-referred',
    PROFILE: '/profile',
    NEW_NOTE: '/new-note',
    SHARE_NOTE: '/share-note',
    NOTIFICATION: '/notification',
    SINGLE_VIEW_NOTE: '/singleViewNote',
    FOLLOW_UNFOLLOW: '/following',
  },
  ReactQuery: {
    LOGIN: 'LOGIN_AUTH',
    REGISTER: 'REGISTER_AUTH',
    FORGET_PASSWORD: 'FORGET_PASSWORD_AUTH',
    OTP: 'OTP_AUTH',
    FEED: 'FEED',
    NOTES: 'NOTES',
    NOTE: 'NOTE'
  },
  LocalStorage: {
    TOKEN: 'token',
    TEMP_TOKEN: 'temp-token',
    REFRESH_TOKEN: 'refresh_token'
  },
  EndpointServer: {
    BASE: '/',
    AUth: {
      LOGIN: '/profile/login/',
      REGISTER: '/profile/register/',
      FORGET_PASSWORD: '/profile/forget-password/send-email/',
      VERIFY_REGISTER: '/profile/verify-register/',
      GOOGLE_LOGIN: '/profile/google-login/'
    },
    FEED: '/profile/feed/',
    NOTES: '/notes/'
  }
};
