import React, { ReactElement } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Loading from 'src/components/loading/Loading';
import { ProtectedRoute, otpRoutes, privateRoutes, publicRoutes } from './routesConfig';
import { useAppStore } from 'src/hooks/useAppStore';
import { AppStoreZustand, RouteItem } from 'src/types/global.type';
import { EmptyPage } from 'src/pages';

export const AppRoutes = (): ReactElement => {
  const { getToken, getTempToken }: AppStoreZustand = useAppStore();
  //todo: delete ! not
  const isAuth = getToken();
  const isOtp = getTempToken();

  return (
    <React.Suspense fallback={<Loading />}>
      <BrowserRouter>
        <Routes>
          {/* public routes */}
          <Route element={<ProtectedRoute isAllowed={!isAuth} redirectPath="/" />}>
            {publicRoutes.map((route: RouteItem) => (
              <Route key={route.path} path={route.path} element={route.element} />
            ))}
          </Route>
          {/* otp steps */}
          <Route element={<ProtectedRoute isAllowed={isOtp} redirectPath="/" />}>
            {otpRoutes.map((route: RouteItem) => (
              <Route key={route.path} path={route.path} element={route.element} />
            ))}
          </Route>
          {/* private routes */}
          <Route element={<ProtectedRoute isAllowed={isAuth} redirectPath="/login" />}>
            {privateRoutes.map((route: RouteItem) => (
              <Route key={route.path} path={route.path} element={route.element} />
            ))}
          </Route>
          <Route path="/*" element={<EmptyPage label="404 Error" />} />
        </Routes>
      </BrowserRouter>
    </React.Suspense>
  );
};
