import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { Constants } from 'src/constants/Constants';
import { Services } from 'src/services/services';
import { useAppStore } from '../useAppStore';
import { toastFn } from 'src/components/toast/toast.fn';
import { ErrorDictionary } from 'src/constants/dictionary';
import { AppStoreZustand, ServerErrorProps } from 'src/types/global.type';
import { VerifyRegisterParams, VerifyRegisterResponse } from 'src/types';

export const useOtpService = () => {
  const navigate = useNavigate();
  const { setToken, setRefreshToken, removeTempToken }: AppStoreZustand = useAppStore();
  const toast = toastFn();
  const reactQuery = useMutation({
    mutationKey: [Constants.ReactQuery.OTP],
    mutationFn: Services.Auth.verifyRegister,
    onSuccess: ({ data }: { data: VerifyRegisterResponse }) => {
      removeTempToken();
      setToken(data?.access);
      setRefreshToken(data?.refresh);
      navigate(Constants.RouteName.HOME);
    },
    onError: (err: ServerErrorProps) => {
      const { error } = err || {};
      const textError = ErrorDictionary.Auth[error.code] || error.message;
      toast.error(textError);
    }
  });

  const post = (params: VerifyRegisterParams) => {
    reactQuery.mutate({
      ...params
    });
  };

  return {
    post
  };
};
