import { useNavigate } from 'react-router-dom';
import { Constants } from '../../constants/Constants';

export const ProfileContent = () => {
  const navigate = useNavigate();
  const followingsCount = '100';
  const followersCount = '12.5 K';
  const wordsCount = '5 K';
  const NoteCount = '780';
  const handleNavigate=()=>{
    navigate(Constants.RouteName.FOLLOW_UNFOLLOW)
  }
  return (
    <div className="w-full flex justify-around items-center">
      <div className="cursor-pointer">
        <div className="text-center">{NoteCount}</div>
        <div className="text-xs">Notes</div>
      </div>
      <div className="cursor-pointer">
        <div className="text-center">{wordsCount}</div>
        <div className="text-xs">Words</div>
      </div>
      <div className="cursor-pointer" onClick={handleNavigate}>
        <div className="text-center">{followersCount}</div>
        <div className="text-xs">Followers</div>
      </div>
      <div className="cursor-pointer" onClick={handleNavigate}>
        <div className="text-center">{followingsCount}</div>
        <div className="text-xs">Followings</div>
      </div>
    </div>
  );
};
