import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { NoteHeader, NoteTools, Notebox } from '.';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import exampleTheme from '../testCode/themes/ExampleTheme';
import { useNotesStore } from '../../hooks';

// import TreeViewPlugin from '../testCode/plugins/TreeViewPlugin';

var editorConfig: any = {
  // The editor theme
  theme: exampleTheme,
  // Handling of errors during update
  onError(error) {
    throw error;
  },

  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode
  ]
};

export function NewNote() {
  const {getNoteItem }: any = useNotesStore();
  let initialConfig = editorConfig;
  if(getNoteItem()?.body){
    initialConfig.editorState = JSON.stringify(getNoteItem()?.body);
  }
  return (
    <div className="relative bg-skin-four w-full m-auto h-screen ">
      <LexicalComposer initialConfig={editorConfig}>
        <NoteHeader />
        <Notebox />
        <NoteTools />
      </LexicalComposer>
    </div>
  );
}
