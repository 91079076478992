import { useState } from 'react';
import { LogoSVG, TextLogoSVG } from 'src/assets/svg/svgIcons';
import ButtonDef from 'src/components/buttons/buttonDef';
import Checkbox from 'src/components/inputs/checkbox';
import InputDef from 'src/components/inputs/inputDef';
import { useRegisterValidation } from 'src/hooks/formValidation/useRegisterValidator';
import { useRegisterService } from 'src/hooks/useAuthService/useRegisterService';

export function Register() {
  const { post, isLoading } = useRegisterService();
  const { register, handleSubmit, errors, isError } = useRegisterValidation();
  const [isAgree, setIsAgree] = useState(false);

  const onSubmit = (data) => {
    if (isLoading) return;
    post(data);
  };
  const toggleIsAgree = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsAgree(e.target.checked);
  };

  return (
    <div className="w-full m-auto bg-skin-five h-screen select-none">
      <div className="pt-10 w-full flex justify-center items-center gap-x-2.5">
        <LogoSVG />
        <TextLogoSVG />
      </div>
      <div className="mt-8 text-skin-one font-normal text-base text-center ">Create Account</div>
      <div className="mt-8">
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputDef
            {...register('email')}
            placeholder={'Email'}
            autoComplete="new-password"
            error={errors?.email?.message || ''}
            className="mt-2"
          />
          <InputDef
            {...register('username')}
            placeholder={'Username'}
            autoComplete="new-password"
            error={errors?.username?.message || ''}
            className="mt-2"
          />
          <InputDef
            {...register('display_name')}
            placeholder={'Name'}
            autoComplete="new-password"
            error={errors?.display_name?.message || ''}
            className="mt-2"
          />
          <InputDef
            {...register('password')}
            placeholder={'Password'}
            autoComplete="new-password"
            error={errors.password?.message || ''}
            className="mt-2"
          />
          <Checkbox name="isAgree" onChange={toggleIsAgree} checked={isAgree} />
          <ButtonDef
            type="submit"
            label={'NEXT'}
            className={'text-skin-t2 bg-skin-one mt-6 m-auto'}
            disabled={!isAgree || isError}
            isLoading={isLoading}
          />
        </form>
      </div>
    </div>
  );
}
