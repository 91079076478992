export default function Checkbox(props) {
  const { ...other } = props || {};

  return (
    <>
      <div className="w-80 h-8 m-auto justify-start items-center gap-1 flex">
        <div className="w-8 h-8 px-4 rounded-lg justify-center items-center flex">
          <div className="text-center text-skin-one text-2xl font-normal leading-normal">
            <input type="checkbox" className="w-4 h-4 accent-skin-t3 " {...other} />
          </div>
        </div>
        <div className="justify-start items-start gap-2.5 flex">
          <div>
            <span className="text-black text-opacity-60 text-sm font-normal leading-normal tracking-wide">
              I accept the{' '}
            </span>
            <span className="text-black text-opacity-60 text-sm font-normal underline leading-normal tracking-wide cursor-pointer">
              terms and conditions
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
