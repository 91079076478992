import React from 'react';
import { Dot3SVG } from 'src/assets/svg/svgIcons';
import { useAppStore } from 'src/hooks/useAppStore';
import { AppStoreZustand } from 'src/types/global.type';

interface HeaderProps {
  children?: React.ReactNode;
}
export default function Header(props: HeaderProps) {
  const { children, ...other } = props || {};
  const { removeAuth }: AppStoreZustand = useAppStore();
  const handleClick = () => {
    if (window.confirm('Do you really want to leave?')) {
      removeAuth();
    }
  };
  return (
    <div
      className="sticky top-0 pt-10 pb-4 w-full px-4 bg-skin-five m-auto z-10 flex justify-start items-center"
      {...other}
    >
      {children}
      <Dot3SVG
        color="#292D32"
        className="absolute right-8 flex items-center cursor-pointer"
        onClick={handleClick}
      />
    </div>
  );
}
