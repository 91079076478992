import { useNavigate } from 'react-router-dom';
import { ArrowIcon, SharedSVG, TagsSVG } from 'src/assets/svg/svgIcons';
import ButtonDef from 'src/components/buttons/buttonDef';
import { SwitcherButton } from 'src/components/buttons/switchButton';
import InputDef from 'src/components/inputs/inputDef';
import { useNotesStore } from '../../hooks';
import { PostNoteService } from '../../types';

export function ShareNote() {
  const navigate = useNavigate();
  // @ts-ignore
  const { noteItem }: { noteItem: PostNoteService } = useNotesStore();
  console.log("=>(shareNote.tsx:13) noteItem", JSON.stringify(noteItem.body));

  const handleBack = () => {
    navigate(-1);
  };
  //todo: search below keys
  // const htmlString = $generateHtmlFromNodes(editor, selection | null);
  // const post = SerializedEditor(noteItem.body);
  return (
    <div className="bg-skin-five w-full m-auto h-screen py-6 px-8">
      <BackButton onClick={handleBack} />
      {/*<NoteItem*/}
      {/*  data={{*/}
      {/*    uid: `uid-${1}`,*/}
      {/*    title: `${noteItem.title}`,*/}
      {/*    body: JSON.stringify(noteItem.body)*/}
      {/*  }}*/}
      {/*/>*/}
      <ShareTypeSelected />
      <BlockComments />
      <Tags />
      <ButtonDef
        label={'SHARE NOTE'}
        className={'bg-skin-one text-white mt-10 h-12'}
        icon={SharedSVG({ color: '#fff' })}
      />
    </div>
  );
}

export function Tags() {
  return (
    <div className="mt-12">
      <div className="text-sm text-skin-six font-medium mt-1">
        Enter the tags associated with your note.{' '}
      </div>
      <div className="text-xs mt-2 text-skin-t4">
        This makes it easy for others to search your note
      </div>
      <div className="mt-3 bg-skin-t5 rounded-[5px] h-[37px] flex justify-start items-center px-3 gap-x-2">
        <TagsSVG size={15} color="#3F3D56" />
        <InputDef
          name={'tags'}
          placeholder={'Enter Tags'}
          onChange={console.log}
          type={'text'}
          className="h-10 border-b-0"
        />
      </div>


    </div>
  );
}

export function BlockComments() {
  return (
    <div className="mt-8 flex justify-between items-center">
      <div className="text-skin-six font-medium">Comment</div>
      <div className="flex justify-end items-center">
        <label
          className="inline-block pl-[0.15rem] hover:cursor-pointer me-2"
          htmlFor="flexSwitchCheckDefault">
          On
        </label>
        <SwitcherButton />
      </div>
    </div>
  );
}

export function ShareTypeSelected() {
  return (
    <>
      <div className="mt-4 text-sm truncate text-skin-six font-medium">
        Who do you want to share this note with?
      </div>
      <div className="mt-4 flex justify-start items-center">
        <div className="bg-zinc-200 rounded-full w-4 h-4" />
        <div className="ms-4">public</div>
      </div>
      <div className="mt-1 flex justify-start items-center">
        <div className="bg-zinc-200 rounded-full w-4 h-4" />
        <div className="ms-4">only followers</div>
      </div>
    </>
  );
}

export function BackButton(props) {
  const { onClick } = props || {};
  return (
    <div
      onClick={onClick}
      className="-ms-2 flex w-min items-center justify-start rounded-lg cursor-pointer px-2 py-1.5 mb-3 hover:bg-zinc-50 flex-grow">
      <ArrowIcon.Left />
      <div className="ms-1 text-base font-medium text-skin-eight">Back</div>
    </div>
  );
}

export const PreviewNote = () => {
  return (
    <div className="relative h-36 bg-neutral-200 rounded-xl mt-8">
      <div className="absolute bottom-0 left-3 right-3 top-4 rounded-t-lg bg-gray-700 text-white p-3">
        <div className="text-sm font-bold">title</div>
        <div className="text-xs leading-5 mt-1 ">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua.
        </div>
      </div>
    </div>
  );
};
