import { useState } from 'react';
import { useAppStore } from '../useAppStore';
import { useMutation } from '@tanstack/react-query';
import { Constants } from 'src/constants/Constants';
import { Services } from 'src/services/services';
import { ErrorDictionary } from 'src/constants/dictionary';
import { toastFn } from 'src/components/toast/toast.fn';
import { AppStoreZustand, ServerErrorProps } from 'src/types/global.type';
import { useNavigate } from 'react-router-dom';
import { LoginParams, LoginResponse } from 'src/types';

export const useloginService = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<LoginResponse>();
  const [error, setError] = useState('');
  const { setToken, setRefreshToken }: AppStoreZustand = useAppStore();
  const toast = toastFn();

  const reactQuery = useMutation({
    mutationKey: [Constants.ReactQuery.LOGIN],
    mutationFn: Services.Auth.login,
    onSuccess: (data: LoginResponse) => {
      setData(data);
      const { success } = data || {};
      if (success) {
        const { access, refresh } = data.data || {};
        setToken(access);
        setRefreshToken(refresh);
        navigate('/');
      }
    },
    onError: (err: ServerErrorProps) => {
      const { error } = err || {};
      const textError = ErrorDictionary.Auth[error.code] || error.message;
      setError(textError);
      toast.error(textError);
    }
  });

  const post = (params: LoginParams) => {
    reactQuery.mutate({
      ...params
    });
  };

  return {
    error,
    post,
    data,
    isLoading: reactQuery?.isPending
  };
};
