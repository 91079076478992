import { useNavigate } from 'react-router-dom';
import {  GoogleSVG, LogoSVG, TextLogoSVG } from 'src/assets/svg/svgIcons';
import ButtonBrand from 'src/components/buttons/buttonBrand';
import ButtonDef from 'src/components/buttons/buttonDef';
import InputDef from 'src/components/inputs/inputDef';
import { Constants } from 'src/constants/Constants';
import { useLoginValidation } from 'src/hooks/formValidation/useLoginValidator';
import { useloginService } from 'src/hooks/useAuthService/useLoginService';

// import { Services } from 'src/services/services';

export function Login() {
  const navigate = useNavigate();
  const { register, handleSubmit, errors, isError } = useLoginValidation();
  const { isLoading, post } = useloginService();

  const onSubmit = (data) => {
    if (isLoading) return;
    const { username, password } = data || {};
    if (username && password) {
      post({ username, password });
    }
  };

  const routeTo = (pathName: string) => {
    navigate(pathName);
  };
  return (
    <div className="w-full m-auto bg-skin-five h-screen select-none flex flex-col justify-start items-center">
      <div className="pt-10 w-full flex justify-center items-center gap-x-2.5">
        <LogoSVG />
        <TextLogoSVG />
      </div>

      <div className="mt-8 text-skin-t1 font-normal text-base text-center ">
        Login to your Account
      </div>
      <div className="mt-8">
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputDef
            {...register('username')}
            name={'username'}
            placeholder={'Username'}
            autoComplete="new-password"
            error={errors['username']?.message || ''}
          />
          <InputDef
            {...register('password')}
            placeholder={'Password'}
            type={'password'}
            className="mt-2"
            error={errors['password']?.message || ''}
          />
          <ButtonDef
            type="submit"
            label={'LOGIN'}
            className={'text-skin-t2 bg-skin-one mt-6 m-auto'}
            disabled={isError || isLoading}
            isLoading={isLoading}
          />
        </form>
        <div
          className="text-center text-xs mt-6 text-skin-one cursor-pointer"
          onClick={() => routeTo(Constants.RouteName.FORGET_PASSWORD)}>
          Forget password
        </div>
        <div className="text-center text-xs mt-6 text-skin-one flex items-center justify-center">
          Don&apos;t have an account?{' '}
          <p
            className=" mx-1 cursor-pointer text-skin-one underline"
            onClick={() => routeTo(Constants.RouteName.REGISTER)}>
            Create now
          </p>
        </div>
        <div className="text-center text-xs mt-16 text-skin-t1">
          Or continue with
        </div>
        <div className="mt-4 flex justify-center items-center w-full gap-x-4">
          <ButtonBrand
            label={'Google'}
            className={'bg-skin-seven text-skin-six h-11 shadow-md w-full  !flex gap-x-2 justify-center items-center'}
            icon={GoogleSVG({})}
          />
        </div>
        <div className="text-center text-sm mt-10 text-skin-one font-light">
          Terms and condition
        </div>
      </div>
    </div>
  );
}
