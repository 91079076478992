import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavbarItems } from './navbar.data';

export default function Navbar(props) {
  const { activeIndex = 0 } = props || {};
  const [selectedItem, setSelectedItem] = useState(NavbarItems[activeIndex]);
  const navigate = useNavigate();
  return (
    <div className="fixed bottom-0 max-w-default min-w-default bg-skin-seven w-full m-auto h-[54px]">
      <div className="grid grid-cols-4 text-10 font-semibold h-full">
        {NavbarItems.map((item) => {
          const isSelected = item.id === selectedItem?.id;
          const icon = isSelected ? item.selectedIcon : item.icon;
          return (
            <div
              key={`navbar-item-id-${item.id}`}
              className={`hover:bg-zinc-100 cursor-pointer w-full`}
              onClick={() => {
                setSelectedItem(item);
                navigate(item.link);
              }}>
              <div className="relative w-fit h-full flex flex-col m-auto">
                {isSelected && (
                  <div className="absolute -top-[1px] m-auto w-full h-[3px] bg-skin-one rounded-full"></div>
                )}
                <div className="m-auto mt-2 w-6 h-6">
                  {icon({
                    size: 24,
                    color: '#314D85'
                  })}
                </div>
                <div
                  className={`my-1 px-1.5 text-center ${
                    isSelected ? 'text-skin-one' : 'text-zinc-600'
                  }`}>
                  {item.title}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
