import { Schema } from 'src/utils/yupSchema';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export function useLoginValidation() {
  const validationSchema: any = yup.object().shape({
    // username: yup.mixed().oneOf([Schema.UserName, Schema.Email]),
    username: Schema.UserName,
    password: Schema.Password
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });
  const isError = () => {
    return Object.keys(errors).length > 0;
  };

  return {
    register,
    handleSubmit,
    errors,
    isError: isError()
  };
}
