import NoteItem from 'src/components/card/noteItem';
import initialState from 'src/constants/fakeData/initialState.json';

export const Notes = () => {

  return (
    <div className="grid grid-cols-1 gap-4 m-4 ">
      {new Array(20).fill(null).map((_, index) => {
        return <NoteItem key={`note-item-key-${index}`}
                         data={{
                           uid: `uid-${index}`,
                           title: `Note ${index}`,
                           body: JSON.stringify(initialState)
                         }}
                         readOnly={true} />;
      })}
    </div>
  );
};
