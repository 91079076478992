import { LogoSVG, TextLogoSVG } from 'src/assets/svg/svgIcons';
import { useNavigate } from 'react-router-dom';
import { Constants } from 'src/constants/Constants';
import ButtonDef from 'src/components/buttons/buttonDef';

export function SuccessSendEmail() {
  const navigate = useNavigate();
  const routeTo = (pathName: string) => {
    navigate(pathName);
  };
  return (
    <div className="w-full m-auto bg-skin-five h-screen text-skin-six">
      <div className="w-[312px] mx-auto select-none flex flex-col justify-start items-center">
        <div className="pt-10 w-full flex justify-center items-center gap-x-2.5">
          <LogoSVG />
          <TextLogoSVG />
        </div>
        <h2 className="mt-[74px] text-opacity-70 text-lg text-center font-bold leading-relaxed tracking-wide">
          Check your Email
        </h2>
        <span className="mt-6 px-4 font-normal text-sm text-justify">
          We send a reset password instruction to your email if your email exists.
        </span>
        <ButtonDef
          label={'BACK TO LOGIN PAGE'}
          className={'text-skin-t2 bg-skin-one mt-8 m-auto'}
          disabled={false}
          isLoading={false}
          onClick={() => routeTo(Constants.RouteName.LOGIN)}
        />
      </div>
    </div>
  );
}
