import { memo } from 'react';
import { Loading3dot } from '../loading/btnLoading';

function ButtonDef(props) {
  const { icon, label, type, onClick, className, disabled, isLoading, ...other } = props || {};

  const handleOnClick = (e) => {
    if (disabled) {
      return;
    }
    onClick && onClick(e);
  };

  return (
    <button
      type={type || 'button'}
      onClick={handleOnClick}
      className={`relative flex justify-center text-sm font-medium
                 items-center rounded-10 shadow-md w-80 !h-10 
                 hover:bg-opacity-90 ${className} ${
                   disabled ? ' cursor-default opacity-70 bg-opacity-70' : ' cursor-pointer '
                 }`}
      {...other}>
      {icon && <div className="me-4 text">{icon || <></>}</div>}
      <div>{label}</div>
      {isLoading ? <Loading3dot className="ms-2" /> : <></>}
    </button>
  );
}
export default memo(ButtonDef);
