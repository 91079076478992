import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { toastFn } from 'src/components/toast/toast.fn';
import { Constants } from 'src/constants/Constants';
import { ErrorDictionary } from 'src/constants/dictionary';
import { Services } from 'src/services/services';
import { ServerErrorProps } from 'src/types/global.type';

export const useForgetPasswordService = () => {
  // const { dispatch } = useContext(ToastContext);
  const [error, setError] = useState('');
  const toast = toastFn();

  const reactQuery = useMutation({
    mutationKey: [Constants.ReactQuery.FORGET_PASSWORD],
    mutationFn: Services.Auth.forgetPassword,
    onError: (err: ServerErrorProps) => {
      const { error } = err || {};
      if (error) {
        const textError = ErrorDictionary.Auth[error.code] || error.message;
        setError(textError);
        toast.error(textError);
      } else {
        setError('is error');
        toast.error('trace error : useForgetPasswordService');
      }
    }
  });

  const postAsync = (params) => {
    return reactQuery.mutateAsync({
      ...params
    });
  };
  const post = (params) => {
    reactQuery.mutate({
      ...params
    });
  };

  return {
    error,
    post,
    postAsync,
    isLoading: reactQuery.isPending
  };
};
