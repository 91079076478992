import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LogoSVG, TextLogoSVG } from 'src/assets/svg/svgIcons';
import ButtonDef from 'src/components/buttons/buttonDef';
import InputDef from 'src/components/inputs/inputDef';
import { Constants } from 'src/constants/Constants';
import { useForgetPasswordService } from 'src/hooks/useAuthService/useForgetPasswordService';
import { SuccessSendEmail } from './successSendEmail';
import { useForgetPasswordValidation } from 'src/hooks/formValidation/useForgetPasswordValidator';

export function ForgetPassword() {
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);
  const { errors, isError, handleSubmit, register } = useForgetPasswordValidation();
  const { isLoading, postAsync } = useForgetPasswordService();

  const routeTo = (pathName: string) => {
    navigate(pathName || '/');
  };

  const onSubmit = (data) => {
    if (isLoading) return;
    postAsync(data).then(() => setIsSubmit(true));
  };
  if (isSubmit) return <SuccessSendEmail />;
  return (
    <div className="w-full m-auto bg-skin-five h-screen text-skin-six">
      <div className=" select-none flex flex-col justify-start items-center">
        <div className="pt-10 w-full flex justify-center items-center gap-x-2.5">
          <LogoSVG />
          <TextLogoSVG />
        </div>
        <div className="mt-11 m-auto">
          <span className="text-opacity-70 text-sm text-center font-medium leading-relaxed tracking-wide">
            Enter your email to recover password
          </span>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputDef
            {...register('email')}
            name={'email'}
            placeholder={'Email'}
            error={errors['email']?.message || ''}
            className="mt-16"
          />
          <ButtonDef
            type={'submit'}
            label={'SEND CODE'}
            className={'text-skin-t2 bg-skin-one mt-8 m-auto'}
            disabled={isError || isLoading}
            isLoading={isLoading}
          />
        </form>
        <div className="h-6 mt-8 justify-start items-start gap-2.5 inline-flex">
          <div className="text-black text-opacity-60 text-sm font-normal leading-normal tracking-wide">
            Don&apos;t have an account?
            <span
              onClick={() => routeTo(Constants.RouteName.REGISTER)}
              className="mx-1 cursor-pointer underline">
              Create now
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
