export const ErrorDictionary = {
  Auth: {
    1001: 'this username is not registered',
    1002: 'bad request',
    1007: 'user already exist',
    2001: 'invalid token',
    2002: 'user is not found',
    2003: 'user is not verify'
  }
};
