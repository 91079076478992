import {
  BellSVG,
  HomeSVG,
  SearchSVG,
  SelectedBellSVG,
  SelectedHomeSVG,
  SelectedSearchSVG,
  SelectedUserProfileSequareSVG,
  UserProfileSequareSVG
} from 'src/assets/svg/svgIcons';
import { Constants } from 'src/constants/Constants';

export const NavbarItems = [
  {
    id: 1,
    title: 'Home',
    icon: HomeSVG,
    selectedIcon: SelectedHomeSVG,
    link: Constants.RouteName.HOME
  },
  {
    id: 2,
    title: 'New',
    icon: SearchSVG,
    selectedIcon: SelectedSearchSVG,
    link: Constants.RouteName.NEW_NOTE
  },
  {
    id: 3,
    title: 'Notification',
    icon: BellSVG,
    selectedIcon: SelectedBellSVG,
    link: Constants.RouteName.NOTIFICATION
  },
  {
    id: 4,
    title: 'Profile',
    icon: UserProfileSequareSVG,
    selectedIcon: SelectedUserProfileSequareSVG,
    link: Constants.RouteName.PROFILE
  }
];
