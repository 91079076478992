export const EmptyPage = (props) => {
  const { label } = props || {};
  return (
    <div
      className="text-6xl font-bold w-screen h-screen max-w-default m-auto
     flex items-center justify-center">
      {label}
    </div>
  );
};
