import { ProfileEditSVG, SettingsSVG } from 'src/assets/svg/svgIcons';

export const ProfileHeader = () => {
  return (
    <div className="flex justify-between items-center p-6">
      <div className="flex justify-start items-center">
        <img
          src={require('src/assets/images/dev/fake_image.png')}
          alt="ssss"
          className="w-24 h-24 rounded-full"
        />
        <div className="ms-2">
          <div>Michelle Chavez</div>
          <div className="text-sm text-gray-700">@michellechavez</div>
        </div>
      </div>
      <div className="flex justify-end items-center">
        <ProfileEditSVG className="me-2 cursor-pointer hover:opacity-90" />
        <SettingsSVG className="ms-2 cursor-pointer hover:opacity-90" />
      </div>
    </div>
  );
};
