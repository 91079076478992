import Header from 'src/components/header/header';
import { HomeBody } from './homeBody';
import MainLayout from 'src/layouts/main';
// import { useQuery } from '@tanstack/react-query';
// import { Constants } from 'src/constants/Constants';
// import { Services } from 'src/services/services';
// import Loading from 'src/components/loading/Loading';
import { TextLogoSVG } from 'src/assets/svg/svgIcons';

export const Home = () => {
  // useDebugValue('Online');

  // const { isLoading, error } = useQuery({
  //   queryKey: [Constants.ReactQuery.FEED],
  //   queryFn: () => Services.Feed.get({ limit: 100, offset: 1 }),
  //   retry: false
  // });
  // if (isLoading) {
  //   <Loading />;
  // }
  // if (error) {
  //   <Loading />;
  // }
  return (
    <MainLayout className="w-full m-auto bg-skin-five">
      <Header>
        <TextLogoSVG />
      </Header>
      <HomeBody />
    </MainLayout>
  );
};
