import Header from 'src/components/header/header';
import { NotificationsList } from './notificationList';
import MainLayout from 'src/layouts/main';

export function Notification() {
  return (
    <MainLayout className="w-full m-auto bg-skin-five">
      <Header>
        <div className="text-xl font-medium text-skin-six">Notification</div>
      </Header>
      <NotificationsList />
    </MainLayout>
  );
}
