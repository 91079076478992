import Editor from './Editor';

export function TestCode() {
  return (
    <div className="w-full m-auto bg-skin-five h-screen">
      <Editor />
    </div>
  );
}

export default TestCode;
