import { Constants } from 'src/constants/Constants';
import { AppStoreZustand } from 'src/types/global.type';
import { localStorageService } from 'src/utils/localStorage';
import { create } from 'zustand';

export const useAppStore = create<AppStoreZustand>((set, get) => ({
  token: null,
  refreshToken: null,
  tempToken: null,
  setTempToken: (tempToken: string | null) => {
    if (!tempToken) return;
    set({ tempToken });
    localStorageService.setItem(Constants.LocalStorage.TEMP_TOKEN, tempToken);
  },
  getTempToken: () => {
    if (get().tempToken) {
      return get().tempToken;
    } else {
      try {
        localStorageService
          .getItem(Constants.LocalStorage.TEMP_TOKEN)
          .then((tempToken) => {
            if (tempToken) {
              set({ tempToken: JSON.stringify(tempToken) });
              return tempToken;
            }
            return null;
          })
          .catch(() => {
            return null;
          });
      } catch (error) {}
      return null;
    }
  },
  removeTempToken: () => {
    localStorageService.removeItem(Constants.LocalStorage.TEMP_TOKEN);
    set({ tempToken: null });
  },
  setToken: (token: string | null) => {
    //todo: check if token is valid for example equals with JWT structure
    if (!token) return;
    set({ token });
    localStorageService.setItem(Constants.LocalStorage.TOKEN, token);
  },
  getToken: () => {
    if (get().token) {
      return get().token;
    } else {
      try {
        localStorageService.getItem(Constants.LocalStorage.TOKEN).then((token) => {
          if (token) {
            set({ token: JSON.stringify(token) });
            return token;
          }
          return null;
        });
      } catch (error) {
        console.error('😏 => error:', error);
      }
    }
    // return 'FAKE_TOKEN';
    return null;
  },
  removeToken: () => {
    localStorageService.removeItem(Constants.LocalStorage.TOKEN);
    set({ token: null });
  },
  setRefreshToken: (refreshToken: string | null) => {
    //todo: check if token is valid for example equals with JWT structure
    if (!refreshToken) return;
    set({ refreshToken });
    localStorageService.setItem(Constants.LocalStorage.REFRESH_TOKEN, refreshToken);
  },
  getRefreshToken: () => {
    if (get().refreshToken) {
      return get().refreshToken;
    } else {
      try {
        localStorageService
          .getItem(Constants.LocalStorage.REFRESH_TOKEN)
          .then((refreshToken) => {
            if (refreshToken) {
              set({ refreshToken: JSON.stringify(refreshToken) });
              return refreshToken;
            }
            return null;
          })
          .catch(() => {
            return null;
          });
      } catch (error) {}
      return null;
    }
  },
  removeRefreshToken: () => {
    localStorageService.removeItem(Constants.LocalStorage.REFRESH_TOKEN);
    set({ refreshToken: null });
  },
  removeAuth: () => {
    localStorageService.removeItem(Constants.LocalStorage.TOKEN);
    localStorageService.removeItem(Constants.LocalStorage.TEMP_TOKEN);
    localStorageService.removeItem(Constants.LocalStorage.REFRESH_TOKEN);
    set({ token: null });
    set({ tempToken: null });
    set({ refreshToken: null });
  }
}));
