import * as yup from 'yup';

export const Schema = {
  Email: yup
    .string()
    .required('Email is required')
    .matches(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/, 'Invalid email address'),

  Password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters'),
  MobileNumber: yup
    .string()
    .matches(/^[0-9]{10}$/, 'Mobile number is not valid')
    .required('Mobile number is required'),
  Birthday: yup.date().required('Birthday is required'),
  Age: yup.number().positive().integer().required('Age is required'),
  UserName: yup.string().required('Username is required'),
  DisplayName: yup.string().required('Display name is required'),
  NewPassword: yup
    .string()
    .required('New password is required')
    .min(8, 'New password must be at least 8 characters')
    .oneOf([yup.ref('password'), ''], 'New password must match with the password')
};
