import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useNavigate } from 'react-router-dom';
import { ArrowIcon } from 'src/assets/svg/svgIcons';
import { Constants } from 'src/constants/Constants';
import { useNoteService, useNotesStore } from 'src/hooks';

export function NoteHeader() {
  const { post } = useNoteService();
  const { noteItem,saveNote }: any = useNotesStore();
  const navigate = useNavigate();
  const [editor] = useLexicalComposerContext();

  function handleSaveAndSend() {
    const noteText = editor?.getEditorState().toJSON();
    const data = {
      title: noteItem.title,
      body: noteText,
      published: false,
      collection_ids: []
    }
    post(data);
    saveNote(data);
  }
  const routeTo = (pathName: string) => {
    navigate(pathName || '/');
  };

  return (
    <div className="flex justify-between items-center pt-7 px-8 text-skin-t1">
      <div className="flex items-center -ms-1" onClick={() => routeTo(Constants.RouteName.HOME)}>
        <ArrowIcon.Left size={18} color="#000" />
        <div className="text-13 font-medium ps-1">Back</div>
      </div>
      <div
        onClick={handleSaveAndSend}
        className="bg-skin-one text-13 font-bold text-white w-20 h-7 flex justify-center items-center rounded cursor-pointer hover:bg-blue-900">
        Share
      </div>
    </div>
  );
}
