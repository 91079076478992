export const localStorageService = {
  // Get an item from local storage.
  getItem: (key: string) => {
    return new Promise((resolve, reject) => {
      try {
        const item = localStorage.getItem(key);
        resolve(item);
      } catch (error) {
        reject(error);
      }
    });
  },

  // Set an item in local storage.
  setItem: (key: string, value: string) => {
    return new Promise((resolve, reject) => {
      try {
        localStorage.setItem(key, value);
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  },

  // Remove an item from local storage.
  removeItem: (key: string) => {
    return new Promise((resolve, reject) => {
      try {
        localStorage.removeItem(key);
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  },

  // Clear all items from local storage.
  clear: () => {
    return new Promise((resolve, reject) => {
      try {
        localStorage.clear();
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }
};
