import Navbar from 'src/components/navbar/navbar';
import ProfileTab from 'src/components/tab/profileTab';
import { Notes } from './notes';
import { ProfileHeader } from './profileHeader';
import { ProfileContent } from './profileContent';
// import { useQuery } from '@tanstack/react-query';
// import { Constants } from 'src/constants/Constants';
// import { Services } from 'src/services/services';
// // import Loading from 'src/components/loading/Loading';

export function Profile() {
  //{"success":true,"data":{"uid":"Zp8KSDyl"}}
  // const { isLoading, error } = useQuery({
  //   queryKey: [Constants.ReactQuery.NOTE],
  //   queryFn: () => Services.Note.get({ note_uid: 'Zp8KSDyl' }),
  //   retry: false
  // });
  // if (isLoading) {
  //   // <Loading />;
  // }
  // if (error) {
  //   // <Loading />;
  // }
  return (
    <div className="w-full m-auto overflow-x-hidden">
      <div className={'bg-skin-five rounded-b-3xl pb-4'}>

        <ProfileHeader />
        <ProfileContent />
        <div className="text-xs w-full mt-4 mx-6 leading-5">
          Thinker. Gamer. Bacon ninja. Web advocate. Friend of animals everywhere. Food enthusiast.

        </div>
      </div>
      <div className={'bg-skin-five py-4 bg-white'}>
        <ProfileTab />
        <Notes />
      </div>
      <Navbar activeIndex={3} />
    </div>
  );
}
