import { Schema } from 'src/utils/yupSchema';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export function useForgetPasswordValidation() {
  const validationSchema: any = yup.object().shape({
    email: Schema.Email
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });
  const isError = () => {
    return Object.keys(errors).length > 0;
  };

  return {
    register,
    handleSubmit,
    errors,
    isError: isError()
  };
}
