import { useState, useRef, useEffect } from 'react';
import { LogoSVG, TextLogoSVG } from 'src/assets/svg/svgIcons';
import { TimerCounter } from 'src/components/countDown/timerCountDown';
import { useAppStore } from 'src/hooks/useAppStore';
import { useOtpService } from 'src/hooks/useAuthService/useOtpService';
import { AppStoreZustand } from 'src/types/global.type';
import { Utils } from 'src/utils/utils';

let targetTimer: Date = new Date();
export const Otp = () => {
  const { post } = useOtpService();
  const { getTempToken }: AppStoreZustand = useAppStore();
  const [otpValue, setOtpValue] = useState('2024');
  const otpLength = 4;
  const inputRefs: any = useRef([]);
  const tempToken = getTempToken();

  useEffect(() => {
    //todo: delete local and get server
    targetTimer = Utils.Convert.addSecondsToDate(120) || new Date();
  }, []);

  const handleToLogin = () => {
    if (otpValue === '2024' && tempToken) {
      post({
        temp_token: tempToken,
        otp: otpValue
      });
    }
  };
  const handleOtpChange = (e, index) => {
    const { value } = e.target;
    const newOtpValue = otpValue.split('');
    newOtpValue[index] = value;
    setOtpValue(newOtpValue.join(''));

    if (value === '') {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    } else if (value.match(/^\d$/)) {
      if (index < otpLength - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0) {
      inputRefs.current[index].focus();
    }
  };

  const otpInputs = Array.from({ length: otpLength }, (_, index) => (
    <input
      key={index}
      type="text"
      maxLength={1}
      value={otpValue[index] || ''}
      onChange={(e) => handleOtpChange(e, index)}
      onKeyDown={(e) => handleKeyDown(e, index)}
      ref={(el) => (inputRefs.current[index] = el)}
      className="w-10 h-fit py-2 border-b border-skin-six text-center bg-skin-five"
    />
  ));
  return (
    <div className="w-full m-auto bg-skin-five h-screen text-skin-six">
      <div className=" select-none flex flex-col justify-start items-center">
        <div className="pt-10 w-full flex justify-center items-center gap-x-2.5">
          <LogoSVG />
          <TextLogoSVG />
        </div>
        <div className="mt-16 w-80  m-auto">
          <div className="">
            <span className="text-opacity-70 text-sm font-medium leading-relaxed tracking-wide">
              To verify your identity, an email containing a code has been sent to: <br />
            </span>
            <span className="text-opacity-70 text-sm font-semibold leading-relaxed tracking-wide">
              <br />
            </span>
            <span className="text-opacity-70 text-sm font-bold leading-relaxed tracking-wide">
              trevor1998@hotmail.com <br />
            </span>
            <span className="text-opacity-70 text-sm font-medium leading-relaxed tracking-wide">
              <br />
              Please check your email and enter the code.
            </span>
          </div>
        </div>
        <div className="mt-2  w-80  m-auto flex justify-evenly items-center gap-x-4">
          {otpInputs}
        </div>

        <div className="mt-8 h-6 justify-start items-start gap-2.5 inline-flex">
          <div className="text-opacity-90 text-sm font-normal leading-normal tracking-wide">
            Did you not receive a code?
          </div>
        </div>
        <div
          className="mt-2 h-6 justify-start items-center gap-2.5 inline-flex"
          onClick={handleToLogin}>
          <div className="text-opacity-10 text-sm font-normal leading-normal tracking-wide text-center">
            <TimerCounter targetTimer={targetTimer} onAction={console.log} />
          </div>
        </div>
      </div>
    </div>
  );
};
