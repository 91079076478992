
export default function ProfileTab() {
  return (
    <div className="px-6 w-full h-8 text-sm flex justify-start items-center gap-x-4">
      <div className="w-24 h-7 px-2.5 py-1.5 bg-blue-900 rounded-2xl justify-center items-center gap-2.5 inline-flex cursor-pointer hover:bg-blue-950">
        <div className="text-center text-white text-xs font-medium">All Notes (16)</div>
      </div>
      <div className="w-24 h-7 px-2.5 py-1.5 bg-zinc-100 rounded-2xl justify-center items-center gap-2.5 inline-flex cursor-pointer hover:bg-zinc-200">
        <div className="text-center text-neutral-600 text-xs font-normal">Collection (3)</div>
      </div>
    </div>
  );
}
