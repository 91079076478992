import { BottomProps } from 'src/types';

export default function ButtonBrand(props: BottomProps) {
  const { icon, label, onClick, className, ...other } = props || {};

  return (
    <div
      className={`relative grid grid-rows-2 rounded-lg shadow-md w-full h-10 cursor-pointer hover:opacity-70 hover:shadow ${className}`}
      onClick={onClick}
      {...other}>
      {icon && <div className="flex justify-center items-end">{icon || <></>}</div>}
      <div className="flex justify-center items-center">{label}</div>
    </div>
  );
}
