import { Schema } from 'src/utils/yupSchema';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export function useRegisterValidation() {
  const validationSchema = yup.object().shape({
    email: Schema.Email,
    password: Schema.Password,
    username: Schema.UserName,
    display_name: Schema.DisplayName
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });
  const isError = () => {
    return Object.keys(errors).length > 0;
  };

  return {
    register,
    handleSubmit,
    errors,
    isError: isError()
  };
}
