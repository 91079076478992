import { BookmarkSVG, EyeSVG, LikeSVG, MessageSVG, SendSVG } from 'src/assets/svg/svgIcons';
import { GetNoteService } from 'src/types';
import { useNavigate } from 'react-router-dom';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import exampleTheme from '../../pages/testCode/themes/ExampleTheme';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { Constants } from '../../constants/Constants';

type NoteItemPropsType = {
  data: GetNoteService;
  readOnly: boolean;
}
var editorConfig: any = {
  // The editor theme
  theme: exampleTheme,
  // Handling of errors during update
  onError(error) {
    throw error;
  },
  editable: false,
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode
  ]
};
export default function NoteItem(props: NoteItemPropsType) {
  const { data, readOnly } = props || {};
  const { title, body }: any = data;
  const navigate = useNavigate();
  // @ts-ignore
  const handleNavigate = (path: string) => {
    navigate(path);
  };
  editorConfig.editorState = body;
  editorConfig.editable = !!readOnly;

  return (
    <div className="w-full rounded-[19px] border mb-3 p-4">
      <div onClick={() => handleNavigate(Constants.RouteName.SINGLE_VIEW_NOTE)}>
        <div id="header-item" className="w-full flex justify-start items-center gap-x-1">
          <img
            src={require('src/assets/images/dev/fake_image.png')}
            className="w-8 h-8 rounded-full"
            alt={'profile_image'}
          />
          <div className="text-xs font-bold">Marilyn Beck</div>
          <div className="text-10 font-normal opacity-40"> 17 APR</div>
        </div>
        <div id="title-item" className="my-1 text-sm font-semibold leading-normal">
          {title}
        </div>
        <LexicalComposer initialConfig={editorConfig}>
          <ContentEditable
            readOnly={!!readOnly}
            contentEditable={!!readOnly}
            suppressContentEditableWarning={false}
            className="not-editable text-13 font-normal leading-5"
            spellCheck={false}
            id="note-text-view"
          />
        </LexicalComposer>
      </div>
      <div id="footer-item" className="mt-[11px] flex justify-between items-center">
        <div className="gap-x-6 flex justify-start items-center">
          <div className="flex items-center">
            <LikeSVG size={18} color="#0D0D0D" className="opacity-40" />
            <div className="text-xs font-normal text-zinc-600 ms-0.5">17K</div>
          </div>
          <div className="flex items-center">
            <MessageSVG size={18} color="#0D0D0D" className="opacity-40" />
            <div className="text-xs font-normal text-zinc-600 ms-0.5">12.3K</div>
          </div>
          <div className="flex items-center">
            <EyeSVG size={18} color="#0D0D0D" className="" />
            <div className="text-xs font-normal text-zinc-600 ms-0.5">2M</div>
          </div>
        </div>
        <div className="gap-x-5 flex justify-end items-center">
          <div className={'cursor-pointer'}>
            <SendSVG size={18} color="#0D0D0D" className="opacity-40" />
          </div>
          <div className={'cursor-pointer'}>
            <BookmarkSVG size={18} color="#0D0D0D" className="opacity-40 " />
          </div>
        </div>
      </div>
    </div>
  );
  // return (
  //   <div className="w-full h-[173px] relative mt-3">
  //     <div className="w-[343px] h-[173px] left-0 top-0 absolute opacity-30 rounded-tl-[15px] rounded-tr-[19px] rounded-bl-[19px] rounded-br-[19px] border border-stone-500" />
  //     <div className="w-[18px] h-[18px] left-[271px] top-[139px] absolute opacity-40">
  //       <div className="w-[18px] h-[18px] left-0 top-0 absolute"></div>
  //     </div>
  //     <div className="w-[179px] h-[18px] left-[16px] top-[139px] absolute">
  //       <div className="w-[38px] h-[18px] left-[141px] top-0 absolute">
  //         <div className="w-[18px] h-[18px] left-0 top-0 absolute opacity-40">
  //           <div className="w-[18px] h-[18px] left-0 top-0 absolute"></div>
  //         </div>
  //         <div className="left-[20px] top-[2px] absolute text-zinc-600 text-xs font-normal font-['Inter']">
  //           2M
  //         </div>
  //       </div>
  //       <div className="w-[41px] h-[18px] left-0 top-0 absolute">
  //         <div className="left-[20px] top-[2px] absolute text-zinc-600 text-xs font-normal font-['Inter']">
  //           17K
  //         </div>
  //         <div className="w-[18px] h-[18px] left-0 top-0 absolute opacity-40">
  //           <div className="w-[18px] h-[18px] left-0 top-0 absolute"></div>
  //         </div>
  //       </div>
  //       <div className="w-[52px] h-[18px] left-[65px] top-0 absolute">
  //         <div className="left-[20px] top-[2px] absolute text-zinc-600 text-xs font-normal font-['Inter']">
  //           12.3K
  //         </div>
  //         <div className="w-[18px] h-[18px] left-0 top-0 absolute opacity-40">
  //           <div className="w-[18px] h-[18px] left-0 top-0 absolute"></div>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="w-[314px] left-[16px] top-[74px] absolute text-stone-950 text-[13px] font-normal font-['Inter'] leading-5">
  //       Lorem ipsum dolor sit amet, consec Lorem ipsum dolor sit amet, consectetur Lorem ip
  //       coectetur dolor sit amet ....{' '}
  //     </div>
  //     <div className="left-[16px] top-[46px] absolute text-stone-950 text-sm font-semibold font-['Inter'] leading-normal">
  //       Title
  //     </div>
  //     <div className="w-7 h-7 left-[16px] top-[10px] absolute">
  //       <div className="w-7 h-7 left-0 top-0 absolute bg-zinc-300 rounded-full" />
  //       <img
  //         className="w-[32.23px] h-[32.23px] left-[-3.17px] top-0 absolute rounded-full"
  //         src={require('src/assets/images/dev/fake_image.png')}
  //       />
  //     </div>
  //     <div className="left-[52px] top-[16px] absolute text-stone-950 text-xs font-bold font-['Inter']">
  //       Marilyn Beck
  //     </div>
  //     <div className="left-[132px] top-[18px] absolute opacity-40 text-center text-stone-950 text-[10px] font-normal font-['Inter']">
  //       17 APR
  //     </div>
  //     <div className="left-[123px] top-[107px] absolute text-blue-900 text-[13px] font-medium font-['Inter'] leading-normal">
  //       Expand Note
  //     </div>
  //     <div className="w-[18px] h-[18px] left-[309px] top-[139px] absolute opacity-40">
  //       <div className="w-[18px] h-[18px] left-0 top-0 absolute"></div>
  //     </div>
  //   </div>
  // );
}
