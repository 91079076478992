import { create } from 'zustand';
import { PostNoteService } from '../../types';

export const useNotesStore = create((set, get) => ({
  noteItem: {
    uid:null,
    title: '',
    body: '',
    published: true,
    collection_ids: []
  },
  saveNote: (item) => {
    if (!item) return;
    const { noteItem }: any = get(); // first we get all notesList
    set({ noteItem: { ...noteItem, ...item } });
  },
  getNoteItem: () => {
    // @ts-ignore
    const { noteItem }: { noteItem:PostNoteService } = get();
    return noteItem;
  }
}));

// const createNote = useNotesStore((state) => state.createNote)
